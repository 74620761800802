<template>
	<div>
		<a-spin :spinning="loading">
      <p style="color: red;">此配置用于云端系统出票座位，手动释放的座位不影响售后。</p>
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择影院组织" v-model:value="formState.organizationId" style="width: 250px;"
							@change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" style="width: 250px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

				</a-row>

				<a-row>
					<a-col :span="18">
<!--						<a-button v-permission="['ciname_basis_filmTicket_add']" type="primary" @click="onAdd">批量释放</a-button>-->
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="cinemaAllList">
					<template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'isRelease'">
              {{record.isRelease ? '是' : '否'}}
            </template>
            <template v-if="column.dataIndex === 'updateReleaseUser'">
              <p v-if="record.updateReleaseUser">{{ record.updateReleaseUser }}</p>
              <p v-else>-</p>
            </template>
            <template v-if="column.dataIndex === 'updateReleaseTime'">
              <p v-if="record.updateReleaseTime">{{ transDateTime(record.updateReleaseTime) }}</p>
              <p v-else>-</p>
            </template>
            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div @click="onEdit(record)">
                      <a-menu-item>
                        编辑
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
					</template>
				</a-table>
			</div>
		</a-spin>
    <a-modal v-model:visible="showModal" title="编辑" @ok="onEditOk" @cancel="showModal = false">
      <a-spin :spinning="loading">
        <a-form  ref="editForm" name="editForm" :model="editData">
          <a-form-item label="配置影院">
<!--            <a-input v-model:value="title" placeholder="请输入"/>-->
            <span>{{ editData.name }}</span>
          </a-form-item>
          <a-form-item label="是否自动释放">
            <a-radio-group v-model:value="editData.isRelease">
              <a-radio :value="1">是</a-radio>
              <a-radio :value="0">否</a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="自动释放时间" extra="单位:分钟。负数表示放映前释放，正数表示开场后释放">
            <a-input-number  v-model:value="editData.releaseTime"/>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
  import {
    getOrganizationList,
    getCinemaList,
    getCinemaFilmModeList,
    changeCinemaFilmMode,
    deleteCinemaFilmMode, updateReleaseInfo
  } from '@/service/modules/cinema.js';
	export default {
		components: {
			Icon,
		},
		data() {
			return {
				loading: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				time: [],
				formState: {
					buyTicketMode: '',
					isDisabled: '',
					organizationId: 0,
				},
				searchData: {},
				// list: [],
				columns: [{
					title: '所属影院',
					dataIndex: 'name'
				}, {
          title: '自动释放',
          dataIndex: 'isRelease',
        }, {
          title: '释放时间',
          dataIndex: 'releaseTime',
        }, {
          title: '配置人',
          dataIndex: 'updateReleaseUser'
        }, {
					title: '配置时间',
          dataIndex: 'updateReleaseTime'
				}, {
					title: '操作',
					key: 'action',
					width: 120,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getAllCinemaList();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getAllCinemaList();
					}
				},
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
        editData: {},
			}
		},
		created() {
			this.getOrganizationList();
			this.onSearch();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.getAllCinemaList();
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.buyTicketMode = this.searchData.buyTicketMode ? this.searchData.buyTicketMode : undefined;
				this.searchData.isDisabled = this.searchData.isDisabled !== '' ? this.searchData.isDisabled : undefined;
				this.getAllCinemaList();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSubmit() {
				this.$refs.addForm.validateFields().then(async () => {
					this.loading = true;
					try {
						let postData = {
							...this.modelRef
						}
						let ret = await updateFilmMode(postData);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.$refs.addForm.resetFields();
							this.showModal = false;
							this.modelRef = {};
              this.cinemaAllList();
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
			onCancel() {
				this.$refs.addForm.resetFields();
				this.modelRef = {};
				this.showModal = false;
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
					if (this.organizationList.length) {
						this.formState.organizationId = this.organizationList[0].id;
						this.getAllCinemaList(this.formState.organizationId);
					}
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onDisabled(record) {
				this.$confirm({
					title: '提示',
					content: `确定${ record.isDisabled ? '启用' : '禁用' }该配置吗？`,
					onOk: async () => {
						this.loading = true;
						let ret = await changeCinemaFilmMode({
							id: record.id,
							isDisabled: record.isDisabled ? 0 : 1
						});
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(`${ record.isDisabled ? '启用' : '禁用' }成功！`)
							this.getAllCinemaList();
						}
					}
				})
			},
			onDelete(record) {

			},
			onAdd() {
				this.showModal = true;
			},
			onEdit(item) {
				this.id = item.id;
				this.isEdit = true;
				this.editData = item;
				this.showModal = true;
			},
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('上传文件格式不正确');
				}
				const isLt1M = file.size / 1024 / 1024 < 2;
				if (!isLt1M) {
					this.$message.error('上传文件太大了');
				}
				return isJpgOrPng && isLt1M;
			},
      async onEditOk() {
        this.loading = true;
        try {
          const postData = {
            id: this.editData.id,
            isRelease: this.editData.isRelease,
            releaseTime: this.editData.releaseTime
          };
          let ret = await updateReleaseInfo(postData);
          this.getAllCinemaList();
          this.showModal = false;
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }

      },
			handleChange(info) {
				if (!info.file.status) {
					this.fileList.splice(this.fileList.length - 1, 1);
				}
				if (info.file.status === 'done') {
					this.modelRef.imgUrl = '';
					const $fileList = [...info.fileList];
					$fileList.map(file => {
						if (file.response) {
							this.modelRef.imgUrl = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
